import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start();
const context = require.context("controllers", true, /.js$/);
application.load(definitionsFromContext(context));

// Import and register all TailwindCSS Components
import { Alert, Autosave, Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('alert', Alert)
application.register('autosave', Autosave)
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

import NestedForm from "stimulus-rails-nested-form"
application.register("nested-form", NestedForm)

import Flatpickr from 'stimulus-flatpickr'
require("flatpickr/dist/flatpickr.css")
application.register('flatpickr', Flatpickr)

import Clipboard from "stimulus-clipboard"
application.register("clipboard", Clipboard)