import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["approved"];
  
  toggle(event) {
    let formData = new FormData();
    formData.append("teacher_approval[approved]", this.approvedTarget.checked);
    fetch(this.data.get("update-url"), {
      body: formData,
      method: "PATCH",
      credentials: "include",
      dataType: "script",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token"),
      },
    }).then(function (response) {
      if (response.status != 204) {
        event.target.checked = !event.target.checked;
      }
      else {
        event.target.parentElement.parentElement.hidden = true;
      }
    });
  }
}

function getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute("content")
  }